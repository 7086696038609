<template>
  <div id="app">
    <Header v-if="$store.state.isLogged && is_customer && !['Plan', 'HerePlan', 'HereRePlan'].includes($route.name)"></Header>
    <div class="body"><router-view/></div>
    <div class='linear-loader' v-if='progress'>
      <h1 class='pt-2 bold'>Upload progress</h1>
      <span>Upload {{routes_counter || 1}} of </span>
      <span v-if='getUpoadedRoutesFile && !routes_length'>{{ getUpoadedRoutesFile.length }}</span>
      <span v-if='routes_length'>{{ routes_length }}</span>
      <span> routes</span>
      <div class='time' v-if='approxTime && fullTime'>
        approximate time left:
        {{approxTime}} of {{fullTime}} minutes
      </div>
      <div class='inner'>
        <div class='load' :style="{width: progress + '%'}"></div>
      </div>
    </div>
    <Footer v-if="!['Login', 'ForgotPassword', 'ResetPassword'].includes($route.name)"></Footer>
    <Loader/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      is_customer: false,
      progress: 0,
      routes_data: null,
      intervalId: null,
      routes_counter: 0,
      routes_length: 0,
      approxTime: 0,
      fullTime: 0,
    }
  },
  methods:{
    ...mapMutations([
      'loadChargebee',
      'setUpoadedRoutesFile'
    ]),
    isCustomer() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.is_customer = true
      }
    },
    async organisationSupport() {
      try {
        const response = await this.$http.getAuth(`${this.$http.apiUrl()}/organisation-support`);
        localStorage.setItem('supportData', JSON.stringify(response?.data?.organisationSupport));
      } catch (e) {
        console.log(e)
      }
    },
    setBranding(){
      const supportData = JSON.parse(localStorage.getItem('supportData'));
      if (supportData.favicon_url) {
        let faIcon = document.querySelector("link[rel~='icon']");
        faIcon.href = `${this.$http.baseUrl() + '/' + supportData.favicon_url}`;
      }
    },
    async routesSocketResponse() {
      await this.$io.on('upload-routes-progress', async (data) => {
        this.progress = data?.progress;
        this.routes_length = data?.routes_count;
        this.approxTime = data?.approxTime;
        this.fullTime = data?.fullTime;
      });
      await this.$io.on('upload-routes-complete', async () => {
        await this.setUpoadedRoutesFile(null);
        this.progress = 0;
        await this.$router.push({name: 'Routes'});
      });
    },
    async uploadRoutes(result) {
      try {
        const user = JSON.parse(localStorage.getItem('user')) || null;
        await this.$io.emit('upload-routes', {result, user_id: user.id});
        this.progress = 1;

        this.routes_data = result;
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    await this.isCustomer();
    await this.organisationSupport();
    await this.setBranding();
    await this.loadChargebee();
    await this.routesSocketResponse();
  },
  components: {
    Header,
    Footer,
    Loader
  },

  watch: {
    async getUpoadedRoutesFile(val) {
      if (val) {
        await this.uploadRoutes(val)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTopBanner',
      'getUpoadedRoutesFile'
    ]),
  },
}
</script>

<style lang="scss">
$custom_green: #1FBC2F;

html, body, #app {
  min-height: 100vh;
}
.route_sections {
  .data {
    svg, img, icon {
      width: 20px;
      height: 20px;
      display: block;
    }
    .direction_instruction {
      width: 100%;
      .icon {
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
}
.linear-loader {
  width: 350px;
  height: 130px;
  position: fixed;
  bottom: 70px;
  right: 20px;
  border: 1px solid #1FBC2F;
  border-radius: 10px;
  background: rgba(108, 117, 125, 0.4);
  color: #fff;
  font-size: 20px;
  .inner {
    width: 95%;
    margin: auto;
    text-align: center;
    left: 0;
    right: 0;
    height: 15px;
    background: #fff;
    border-radius: 10px;
    transition: width 1s;
    position: absolute;
    bottom: 10px;
    z-index: -1;
    .load {
      height: 15px;
      border-radius: 10px;
      left: 0;
      background: $custom_green;
    }
  }
}

@font-face { font-family: Catamaran-bold;
  src: url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap'); }

.wysiwyg_form {
  font-family: Catamaran-bold, sans-serif;
  max-width: 800px;
  margin: auto;
  margin-left: 0;
  border: 0;
  p {
    font-size: 0.875rem!important;
  }
  .ql-snow .ql-editor {
    h1 {
      font-size: 34px;
      line-height: 34px;
    }

    h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
  .editr--toolbar>div:nth-child(13) {
    display: none;
  }
  h1 {
    font-size: 34px;
    line-height: 34px;
  }
  h2 {
    font-size: 30px;
    line-height: 30px;
  }
  h3 {
    font-size: 26px!important;
    line-height: 26px!important;
  }
  h4 {
    font-size: 22px!important;
    line-height: 22px!important;
  }
  h5 {
    font-size: 18px!important;
    line-height: 18px!important;
  }
  h6 {
    font-size: 14px!important;
    line-height: 14px!important;
  }
  b {
    font-weight: 800!important;
  }
  h1, h2, h3, h4, h5, h6 {
    b {
      font-weight: 800!important;
    }
  }
  .editr {
    width: 100%;
    padding: 0 !important;
    b {
      font-weight: 800!important;
    }
  }
}
.text-120px {
  width: 120px;
  white-space: break-spaces;
  padding: 5px;
  border: 0;
}
.reach_text {
  font-family: Catamaran-bold, sans-serif;
  margin: auto;
  text-align: left;
  font-size: 0.875rem;

  h1 {
    font-size: 34px;
    line-height: 34px;
  }
  h2 {
    font-size: 30px;
    line-height: 30px;
  }
  h3 {
    font-size: 26px;
    line-height: 26px;
  }
  h4 {
    font-size: 22px;
    line-height: 22px;
  }
  h5 {
    font-size: 18px;
    line-height: 18px;
  }
  h6 {
    font-size: 14px;
    line-height: 14px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  table td {
    border: 1px solid #ddd;
    padding: 2px;
  }
}
.bold {
  font-weight: 600;
}
body {
  &.on-loading {
    min-height: auto;
    height: auto;
    overflow-y: hidden;
    .page {
      min-height: auto;
      height: auto;
      overflow-y: hidden;
      .white-green {
        text-decoration: none;
        color: #1FBC2F;
        background: #fff;
        font-weight: bold;
        font-size: 15px;
        line-height: 15px;
        border-radius: 8px;
        padding: 10px;
        border: 2px solid #1FBC2F;
        &:disabled {
          cursor: not-allowed !important;
          opacity: .5;
        }
        &.disabled {
          cursor: not-allowed !important;
          opacity: .5;
        }
      }

    }
  }
}
.selected_table {
  .dropdowns-search {
    &>div {
      width: 66%!important;
    }
  }
}
.add_items {
  &.minified {
    .filters {
      .dropdowns-search {
        flex-direction: column;
        gap: 5px;
        .min_search, .rows-count {
          width: auto;
        }
        .min_search {
          height: 40px;
        }
      }
    }
  }
}
.page {
  min-height: calc(100vh - 200px);
  &.plan {

    .daterangepicker td.active {
      background-color: #357ebd;
      border-color: transparent;
      color: #fff;
    }
    .vue-daterange-picker {
      position: inherit;
      min-width: 300px;
      .drp-buttons {
        position: absolute;
        bottom: 10px;
      }
      .drp-calendar.col.left {
        .calendar-time {
          position: absolute;
          bottom: 65px;
          left: 10px;
        }
      }
      .drp-calendar.col.right {
        .calendar-table {
          display: none;
        }
        .calendar-time {
          position: absolute;
          bottom: 65px;
          left: 140px;
        }
      }
      .daterangepicker.openscenter {
        min-width: 290px;
        width: 290px;
        height: 350px;
      }
      .calendars {
        .ranges {
          display: none;
        }
      }
    }
  }
}
.logo {
  padding: 15px;
  img {
    max-width: 100%
  }
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.gap-20 {
  gap: 20px;
}
.sort {
  &.rotate {
    svg {
      transform: rotate(180deg);
    }
  }
}
.rotate-180 {
  transform: rotate(180deg);
}
.input_group {
  //box-sizing: inherit;
  input:not([type='checkbox']) {
    font-size: 14px;
    line-height: 14px;
    color: #3d4852;
    border: 1px solid #D0D5DA;
    background-color: #fff;
    width: 100%;
    margin: 0;
    font-weight: 500;
    height: 36px;
    border-radius: 30px;
    padding: 15px;
    &::placeholder {
      color: #969a9f;
    }
  }
}
.pseudo-input {
  .dropdown-toggle {
    &:hover, &:focus, &:active {
      background: transparent;
      cursor: pointer;
    }
  }
}
.bordered {
  border: 1px solid #E2E4E6;
  &-r {
    border: 1px solid #E2E4E6;
    border-radius: 2rem;
  }
}
.input-reset {
  &:active, &:hover, &:focus {
    outline: transparent;
  }
}
.full_green_button {
  padding: 10px 15px;
  width: 133px;
  height: 44px;
  background: #1FBC2F;
  svg {
    margin-right: 5px;
  }
  span {
    padding-top: 2px;
  }
}
.routes_table {
  .share_routes {
    .input_group {
      input {
        border-radius: 4px;
      }
    }
  }
  .sms {
    .input_group {
      position: relative;
    }
    .dropdown_block {
      position: absolute;
      width: 100%;
      top: 55px;
      z-index: 10;
      border-radius: 5px;
      background-color: #fff;
    }
  }
}
.roll_back_line {
  color: #6c757d;
  font-size: 1.17em;
  font-weight: 700;
  padding: 10px 15px;
  width: 95%;
  margin: auto;
}
.label_roll_back {
  padding-top: 3px;
}
.login {
  min-height: 100vh;
  padding-top: 100px;
  .login_form {
    border-radius: 8px;
    margin: auto auto;
    max-width: 440px;
    min-height: 420px;
    border: 1px solid #E2E4E6;
    .register {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      text-align: center;
      color: #303741;
    }
    form {
      padding: 15px;
    }
    .top_line {
      font-weight: 700;
      line-height: 21px;
      color: #303741;
      .title {
        padding-top: 15px;
      }
    }
  }
}
.info-block {
  .mark {
    width: 15px;
    height: 15px;
  }
  .l-title {
    color: #8795a1;
    font-weight: 600;
  }
}
.border-grey-lighter {
  border-color: #f1f5f8;
}
.bg-grey-lighter {
  background-color: #f1f5f8;
}
.modal {
  position: fixed;
  right: 0;
  left: 0;
  width: 100vw;
  top: 0;
  padding-top: 25vh;
  background-color: rgba(0,0,0, 0.4);
  height: 100vh;
  &.danger {
    z-index: 10000;
    .btn {
      &.danger {
        color: #F25837;
      }
    }
  }
  .inner {
    display: block;
    max-width: 70vw;
    margin: auto;
    background: white;
    opacity: 1;
    height: fit-content;
    border-radius: 4px;
  }
}
.shifted-left-15 {
  margin-left: -35px;
}
.height-20 {
  max-height: 20px;
}
.border-t {
  border-top-width: 1px;
}
.bordered_b{
  border-bottom: 1px solid #dae1e7;
}
.bordered_t{
  border-top: 1px solid #dae1e7;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-30 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.pb-15 {
  margin-bottom: 15px;
}
.bold-green {
  color: #1FBC2F;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}
.light-green {
  color: #1FBC2F;
  font-size: 14px;
  line-height: 14px;
}
.text-green {
  color: #1FBC2F;
}
p.error {
  &:first-letter {
    text-transform: uppercase;
  }
}
.error, .danger {
  color: red;
}
.fw-b {
  font-weight: bold;
}
.border-table {
  border: 1px solid #dae1e7;
}
.pointer {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed !important;
    opacity: .5;
  }
}
.btn_danger {
  border: 2px solid #F2374D;
  box-sizing: border-box;
  border-radius: 8px;
}
.error_title {
  font-weight: 700;
  font-size: 1.17em;
  line-height: 1.17em;
  color: #F2374D;
}
.w_80 {
  width: 80%;
  margin: auto;
}
.logo_icon {
   .logo {
     img {
       max-width: 65px;
     }
   }
 }
.vehicles {
  .modal-shadow {
    & > .inner {
      top: 10%;
      .content {
        max-height: 500px;
        overflow: scroll;
      }
    }
  }
}
.square_button {
  padding: 10px;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
}
.square_green_button {
  color: #1FBC2F;
  border: 2px solid #1FBC2F;
}
.square_red_button {
  color: #F2374D;
  border: 2px solid #F2374D;
  svg {
    path {
      stroke: #F2374D;
    }
  }
}
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
  &>.inner{
    background: #fff;
    position: absolute;
    top: 25%;
    width: 50%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 5px;
  }
}
button {
  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}
.square_red_button:disabled {
  color: #3d4852;
  border: 2px solid #3d4852;
  svg {
    path {
      stroke: #3d4852;
    }
  }
}
.green_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1FBC2F !important;
}
.in_row_button {
  margin: 0 15px;
  border-radius: 8px;
}
.last_in_row_button {
  margin-left: 15px;
  border-radius: 8px;
}
.d-block {
  display: block;
}
.w_100 {
  width: 100%;
}
.green-white {
  text-decoration: none;
  border-width: 1px;
  color: #fff;
  background: #1FBC2F;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  border-radius: 8px;
  padding: 15px 10px;
  &:hover {
    background: rgba(31, 188, 47, 0.7);
  }
}
.white-green {
  text-decoration: none;
  color: #1FBC2F;
  background: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  border-radius: 8px;
  padding: 10px;
  border: 2px solid #1FBC2F;
  &:hover {
    background: rgba(31, 188, 47, 0.2);
  }
}
.vue2leaflet-map {
  .leaflet-control-container {
    .leaflet-top {
      &.leaflet-left {
        display: none !important;
      }
      &.leaflet-right {
        top: 65px;
      }
    }
  }
}
.p2-p {
  padding: 2px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.toastify {
  z-index: 405;
  width: 100%;
  max-width: 328px;
  margin-right: 0;
  margin-left: auto;
  padding: 15px;
  position: absolute;
  top: 0!important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E6ECF4;
  box-sizing: border-box;
  box-shadow: 0px 1px 8px rgba(12, 88, 176, 0.08);
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #303741!important;
  padding-left: 55px;
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    top: 0;
    bottom: 0;
    margin-left: 15px;
    left: 0;
    z-index: 5;
  }
  &:after {
    content: '';
    width: 44px;
    position: absolute;
    left: 2px;
    height: 95%;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 8px;
  }
  &.error {
    &:before {
      background-image: url('~@/assets/images/stop.svg');
    }
    &:after {
      background: #F2374D;
    }
  }
  &.info {
    &:before {
      background-image: url('~@/assets/images/info.svg');
    }
    &:after {
      background: #2468CA;
    }
  }
  &.warning {
    &:before {
      background-image: url('~@/assets/images/warning.svg');
    }
    &:after {
      background: #FFC01F;
    }
  }
  &.success {
    &:before {
      background-image: url('~@/assets/images/success.svg');
    }
    &:after {
      background: #00DDC6;
    }
  }
  &.open_top_banner {
    top: 70px !important;
  }
  .toast-close {
    color: transparent;
    background-image: url('~@/assets/images/close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    padding: 5px 10px;
    cursor: pointer;
  }
}
.text-primary-dark {
  color: #2276dd;
}
.mb-30 {
  margin-bottom: 30px;
}
.gap-5p {
  gap: 5px;
}
.gap-10p {
  gap: 10px;
}
.text-grey-darker {
  color: #606f7b;
}
.text-grey-dark{
  color: #8795a1;
}
.dropdown-toggle {
  background-image: none !important;
}
.dropdown {
  .btn-group {
    margin: 0!important;
  }
}
html, body, #app {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  font-family: Catamaran, sans-serif;
  font-size: .875rem;
}
.title_line {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 700;
  padding: 30px 15px 22px;
  border-bottom: 1px solid #dae1e7;
  width: 95%;
  margin: auto;
}
.no_borders {
  border: none!important;
}
.shadow {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}
.sh_underlne {
  width: 30px;
  height: 3px;
  background-color: #dae1e7;
}
.content {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  .sidebar {
    border-right: 1px solid #dae1e7;
    padding: 10px 15px;
  }
}
#app {
  text-align: center;
  color: #22292f;
}
.height_av {
  height: 100%;
}
.bg-white {
  background-color: #fff;
}
.header .bg-yellow-lighter, .bg-yellow-lighter {
  background-color: #FFA800;
  color: #ffffff;
}
.text-2xl {
  font-size: 1.5rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.bg-grey-lightest {
  background-color: #f8fafc;
  height: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.input-group {
  input {
    min-height: 2.5rem;
    font-size: .875rem;
    color: #3d4852;
    border-width: 1px;
    border-color: #b8c2cc;
    border-radius: 0.25rem;
    background-color: #fff;
    padding: 0.5rem 1rem;
    &:focus-visible, &:focus, &:hover, &:active {
      outline: transparent !important;
    }
  }
  textarea, input {
    &:focus {
      outline-width: 0.01px;
      outline-color: #b8c2cc;
    }
  }
}
span.required {
  color: #1FBC2F;
}
.container {
  margin: auto;
}
.filters {
  font-size: 14px;
  .add_user {
    margin-top: 35px;
  }
  .bordered {
    border: 1px solid #E2E4E6;
    box-sizing: border-box;
    margin-top: 40px;
    box-shadow: 0px 1px 8px rgba(12, 88, 176, 0.08);
    border-radius: 18px;
    max-height: 36px;
    .dropdown-toggle{
      background: transparent;
      text-align: left;
      padding: 10px 25px;
    }
    #company_names {
      border-right: 2px solid #E5E5E5;
      max-height: 24px;
      .dropdown-toggle{
        padding: 2px 12px;
      }
    }
  }
}
.dropdown-toggle {
  background-image: none!important;
  .caret {
    border: solid #1FBC2F;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 35%;
    width: 8px;
    height: 8px;
  }
}
.fl_table {
  font-size: .875rem;
  min-width: 500px;
  overflow-x: auto;
  .body {
    padding-top: 0;
  }
  input[type=checkbox]:checked {
    accent-color: #FFA800;
  }
  .active {
    background: #FFF1CE;
  }
  .wrap {
    width: 98%;
    margin: auto;
  }
  .header {
    background: #f6f7f8;
    color: #79808e;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
  .body {
    &>div {
      border-bottom: 1px solid rgba(0,0,0,.05);
    }
  }
}
.bg-green-custom {
  background: $custom_green;
}
.text-green-custom {
  --tw-text-opacity: 1;
  color: rgba(31, 188, 47, var(--tw-text-opacity));
}
.border-green-custom {
  border-color: $custom_green;
}
.text_chameleon {
  color: #ffffff;
}
.text_chameleon:hover {
  color: $custom_green;
}
.catalog-column-chooser {
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-md-6 {
    width: 300px;
    text-align: left;
    .table {
      width: 100%;
      .text-center {
        text-align: left;
      }
    }
  }
}
@media screen and (max-width: 1854px) {
  .toastify {
    top: 77px !important;
    &.open_top_banner {
      top: 147px !important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .content {
    max-width: 1100px;
  }
}

@media screen and (max-width: 1199px) {
  .content {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .content {
    max-width: 700px;
  }
}
.my-tooltip {
  position: relative;
}
.my-tooltip .tooltiptext {
  visibility: hidden;
  background-color: #f0f0f0;
  color: #393939;
  text-align: center;
  border-radius: 6px;
  padding: 3px 5px;
  bottom: 20px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}

.my-tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
