<template>
  <div class="part text-grey-darker footer border-t border-grey-lighter py-5">
    <div class="content flex items-center justify-between">
      <div class="privacy">
        <div class="md:flex-auto">
          <ul class="list-reset items-center justify-between w-full gap-5 flex mb-4 md:mb-0 ">
            <li class="">
              <a href="https://lorryroute.com/legal/privacy"
                 class=" text-grey-dark font-medium"
                 target="_blank" rel="noopener noreferrer">Privacy policy</a>
            </li>
            <li class="">
              <a href="https://lorryroute.com/legal/terms-and-conditions"
                 class=" text-grey-dark font-medium"
                 target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
            </li>
            <li class="">
              <a href="https://lorryroute.com/legal/terms-of-service"
                 class=" text-grey-dark font-medium"
                 target="_blank" rel="noopener noreferrer">Terms of service</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="contacts">
        <div class="md:text-right flex gap-5">
          <div class="leading-tight">
            <div>
              <a href="mailto:support@lorryroute.com" class="no-underline hover:underline">Email Customer Services</a>
              or Call
              <a href="tel:020 7952 0450" class="no-underline">020 7952 0450</a>
            </div>
          </div>
          <div class="leading-tight">
            <small>v{{ app_version || '' }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    app_version() {
      return `${process.env.VUE_APP_TOP_VERSION}.${process.env.VUE_APP_MAJOR_VERSION}.${process.env.VUE_APP_MINOR_VERSION}`;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
