<template>
  <div class="component">
    <div class="head-info bg-yellow-lighter" v-if="show">
      <div class="flex py-2 justify-between">
        <div class="w-full msg">
          <p>New traffic and roadworks API is now active on your Lorry Route account!</p>
          <p>Increased route generation will provide results based on current active traffic disruptions, roadworks and future planned disruptions too.</p>
          <p>Compliant Routing. Made Simple.</p>
          <div
              class="close"
              @click="close">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  data(){
    return {
      show: false,
    }
  },
  methods: {
    ...mapMutations([
        'setTopBanner'
    ]),
    close() {
      this.setTopBanner(false);
      this.show = false
    },
  },
  async mounted() {
    if (this.show) {
      this.setTopBanner(true);
    }
  },
  name: "TopBanner"
}
</script>

<style lang="scss" scoped>
.msg {
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 28%;
    bottom: 0;
    margin: auto;
  }
}
</style>
