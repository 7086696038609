<template>
  <div class="page">
    <div class="content">
      <div class="title_line w-full no_borders text-left">
        <h1 class="title">Dashboard</h1>
      </div>
      <div class="inner grid grid-cols-3 gap-15">
        <router-link :to="{name: 'Routes'}" class="item shadow">
          <div class="image">
            <img src="@/assets/images/routes.png" alt="routes">
          </div>
          <div class="inner text-left flex flex-col px-4 py-6">
            <div class="name py-2">
              Routes
            </div>
            <div class="sh_underlne"></div>
            <div class="descr py-2">
              <span class="text-grey-darker">Overview, manage and create compliant routes for your HGV fleet</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{name: 'Vehicles'}" class="item shadow">
          <div class="image">
            <img src="@/assets/images/vehicles.png" alt="vehicles">
          </div>
          <div class="inner text-left flex flex-col px-4 py-6">
            <div class="name py-2">
              Vehicles
            </div>
            <div class="sh_underlne"></div>
            <div class="descr py-2">
              <span class="text-grey-darker">View and manage your fleet</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{name: 'Locations'}" class="item shadow">
          <div class="image">
            <img src="@/assets/images/locations.png" alt="locations">
          </div>
          <div class="inner text-left flex flex-col px-4 py-6">
            <div class="name py-2">
              Locations
            </div>
            <div class="sh_underlne"></div>
            <div class="descr py-2">
              <span class="text-grey-darker">Overview and manage saved locations</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{name: 'Drivers'}" class="item shadow">
          <div class="image">
            <img src="@/assets/images/drivers.png" alt="drivers">
          </div>
          <div class="inner text-left flex flex-col px-4 py-6">
            <div class="name py-2">
              Drivers
            </div>
            <div class="sh_underlne"></div>
            <div class="descr py-2">
              <span class="text-grey-darker">Overview and manage saved locations</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  methods: {


  },
}
</script>

<style lang="scss" scoped>
.page {
  .title_line {
    padding-left: 0;
  }
}
</style>
