<template>
  <div :class="{'modal danger': $store.state.showLogoutPopup}">
    <div class="inner" v-if="$store.state.showLogoutPopup" :class="{'hidden': !$store.state.showLogoutPopup}">
      <div class="flex flex-col">
        <div class="title_line">
          {{alertTitle}}
        </div>
        <div class="actions flex items-center justify-around py-8">
          <button class="green-white" @click="actionHandler">Yes</button>
          <button class="green-white" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  props: {
    alertTitle: {
      type: String,
      default: ''
    }
  },
  methods:{
    ...mapMutations([
      'setShowLogoutPopup'
    ]),
    actionHandler() {
      this.$emit('log-out');
    },
    close(){
      this.setShowLogoutPopup(false)
    },
  },
  name: "ConfirmLogoutPopup"
}
</script>

<style lang="scss" scoped>
.danger {
  .inner {
    padding: 15px 15px 0 15px;
    max-width: 400px;
    .title_line {
      border-bottom: none;
      padding-bottom: 0;
    }
    button {
      min-width: 90px;
    }
  }
}

</style>
