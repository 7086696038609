import Vue from 'vue'
import Vuex from 'vuex'
import http_client from '../services/http_client'
import load from 'little-loader';
import * as helper from "../utils/helper";

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        isLogged: (() => {
            return !!localStorage.getItem('jwt')
        })(),
        isLoginChecked: false,
        searchSelectedOption: {
            name: "United Kingdom",
            id: "GB"
        },
        share_new_route_id: null,
        add_vehicles: false,
        add_trailers: false,
        main_menu: [
            {
                name: 'Plan',
                link: '/plan',
            },
            {
                name: 'Routes',
                link: '/routes',
            },
            {
                name: 'Vehicles',
                link: '/vehicles',
            },
            {
                name: 'Drivers',
                link: '/drivers',
            },
            {
                name: 'Locations',
                link: '/locations',
            },
            {
                name: 'Trailers',
                link: '/trailers',
            },
        ],
        showLogoutPopup: false,
        user_menu: [
            {
                name: 'Account',
                link: '/account/profile',
            },
            {
                name: 'Change plan',
                link: '/change-plan',
            },
            {
                name: 'Addons',
                link: '/account/addons',
            },
            {
                name: 'Invoices',
                link: '/invoices',
            },
        ],
        selectedVehicles: [],
        selectedTrailers: [],
        cbInstance: null,
        multiWaypoints: [],
        itemsToShow: null,
        markers: [],
        selectedFilterItem: null,
        searchNames: '',
        rowsToShift: 0,
        multiWaypointsText: [],
        lowEmissionPoi: [],
        ulez: [],
        london_control_polygon: [],
        london_control_polyline: [],
        loading: false,
        showShareRoutes: false,
        vehiclesDimensions: {
            width: {
                min: 1,
                max: 6,
                val: 2.5,
                step: 0.1,
                name: 'Width',
                dimention: 'm'

            },
            height: {
                min: 1,
                max: 10,
                val: 4,
                step: 0.1,
                name: 'Height',
                dimention: 'm'

            },
            length: {
                min: 1,
                max: 50,
                val: 12,
                step: 0.5,
                name: 'Length',
                dimention: 'm'

            },
            weight: {
                min: 1,
                max: 50,
                val: 18,
                step: 1,
                name: 'Weight',
                dimention: 't'

            },
        },
        trailersDimensions: {
            width: {
                min: 0,
                max: 6,
                val: 0,
                step: 0.1,
                name: 'Width',
                dimention: 'm'

            },
            height: {
                min: 0,
                max: 10,
                val: 0,
                step: 0.1,
                name: 'Height',
                dimention: 'm'

            },
            length: {
                min: 0,
                max: 50,
                val: 0,
                step: 0.5,
                name: 'Length',
                dimention: 'm'

            },
        },
        topBanner: true,
        dropOpen: true,
        upoadedRoutesFile: null,
    },
    mutations: {
        setAddVehicles(state, val) {
            state.add_vehicles= val;
        },
        setUpoadedRoutesFile(state, val) {
            state.upoadedRoutesFile= val;
        },
        setShareNewRouteId(state, val) {
            state.share_new_route_id = val;
        },
        setAddTrailers(state, val) {
            state.add_trailers = val;
        },
        setShowShareRoutes(state, val) {
            state.showShareRoutes = val;
        },
        setSelectedVehicles(state, val) {
          state.selectedVehicles = val
        },
        setSelectedTrailers(state, val) {
          state.selectedTrailers = val
        },
        setLoading(state, val = false) {
            state.loading = !!val;
            if (val) {
                document.body.classList.add('on-loading')
            } else {
                document.body.classList.remove('on-loading')
            }
        },
        setLowEmissionPoi(state, val) {
            if (!val) {
                return state.lowEmissionPoi = []
            }
            state.lowEmissionPoi.push(val);
        },
        setMarkers(state, val) {
            if (!val) {
                return state.markers = []
            }
            state.markers.push(val);
        },
        setVehiclesDimensions(state, val) {
            state.vehiclesDimensions = val
        },
        setTrailersDimensions(state, val) {
            state.trailersDimensions = val
        },
        setLondonControlPolygon(state, val) {
            if (!val) {
                return state.london_control_polygon = []
            }
            state.london_control_polygon.push(val);
        },
        setLondonControlPolyline(state, val) {
            if (!val) {
                return state.london_control_polyline = []
            }
            state.london_control_polyline.push(val);
        },
        setUlez(state, val) {
            if (!val) {
                return state.ulez = []
            }
            state.ulez.push(val);
        },
        setTopBanner(state, val) {
            state.topBanner = val;
        },
        setMultiWaypoints(state, val) {
            state.multiWaypoints.push(val);
        },
        setMultiWaypointsText(state, val) {
            state.multiWaypointsText.push(val);
        },
        clearMultiWaypoints(state) {
            while(state.multiWaypoints.length > 0) {
                state.multiWaypoints.pop();
            }
        },
        clearMultiWaypointsText(state) {
            while(state.multiWaypointsText.length > 0) {
                state.multiWaypointsText.pop();
            }
        },
        setShowLogoutPopup(state, val) {
            state.showLogoutPopup = val;
        },
        setSearchSelectedOption(state, val){
            state.searchSelectedOption = val;
        },
        setItemsToShow(state, val) {
            state.itemsToShow = val;
        },
        setRowsToShift(state, val) {
            state.rowsToShift = val;
        },
        setSelectedFilterItem(state, val) {
            state.selectedFilterItem = val;
        },
        setSearchItemsNames(state, val) {
            state.searchNames = val;
        },
        loginUser(state, args) {
            const {user, access_token} = args;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('jwt', access_token);
            state.isLogged = true;
            state.isLoginChecked = true;
        },
        async refreshLocalUser(){
            let user = JSON.parse(localStorage.getItem('user'));
            let refreshed = await http_client.postAuth(`${http_client.apiUrl()}/users/${user.id}`);
            localStorage.setItem('user', JSON.stringify(refreshed?.data || user));
        },
        logOutUser(state) {
            localStorage.removeItem('user');
            localStorage.removeItem('jwt');
            state.isLogged = false;
            state.isLoginChecked = false;
        },
        async checkToken(state) {
            state.isLoginChecked = false;
            if ( localStorage.getItem('jwt') ) {
                await http_client.refreshToken();
                state.isLoginChecked = true;
            }
        },
        LogOut(state){
            state.isLogged = false;
            state.isLoginChecked = false;
        },

        async loadChargebee(state) {
            if (window.Chargebee) {
                return window.Chargebee;
            }

            return new Promise((resolve, reject) => {
                load('https://js.chargebee.com/v2/chargebee.js', err => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    if (!window.Chargebee) {
                        reject(new Error('Failed to load Chargebee.js'));
                        return;
                    }
                    resolve(window.Chargebee);
                    state.cbInstance = window.Chargebee.init({
                        site: helper.server.chbeeDomain,
                        publishableKey: helper.server.chbeeApiKey
                    });

                });
            });
        },
        setDropState(state, val) {
            state.dropOpen = val;
        },
    },
    getters: {
        getAddVehicles(state) {
            return state.add_vehicles;
        },
        getUpoadedRoutesFile(state) {
            return state.upoadedRoutesFile;
        },
        getAddTrailers(state) {
            return state.add_trailers;
        },
        getShowShareRoutes(state) {
            return state.showShareRoutes;
        },
        getTopBanner(state) {
            return state.topBanner;
        },
        getCbInstance(state) {
            return state.cbInstance
        },
        getMarkers(state) {
            return state.markers
        },
        getSelectedTrailers(state) {
            return state.selectedTrailers
        },
        getSelectedVehicles(state) {
            return state.selectedVehicles
        },
        isAuthenticated(state) {
            return state.isLogged
        },
        getSearchSelectedOption(state) {
            return state.searchSelectedOption
        },
        mainMenu(state) {
            return state.main_menu;
        },
        userMenu(state) {
            return state.user_menu;
        },
        getMultiWaypoints(state) {
            return state.multiWaypoints;
        },
        getMultiWaypointsText(state) {
            return state.multiWaypointsText;
        },
        getItemsToShow(state) {
            return state.itemsToShow;
        },
        getSelectedFilterItem(state) {
            return state.selectedFilterItem;
        },
        getRowsToShift(state) {
            return state.rowsToShift;
        },
        getSearchItemsNames(state) {
            return state.searchNames;
        },
        getLowEmissionPoi(state) {
            return state.lowEmissionPoi;
        },
        getUlez(state) {
            return state.ulez;
        },
        getLondonControlPolygon(state) {
            return state.london_control_polygon;
        },
        getLondonControlPolyline(state) {
            return state.london_control_polyline;
        },
        isLoading(state) {
            return state.loading;
        },
        getVehiclesDimensions(state) {
            return state.vehiclesDimensions;
        },
        getTrailersDimensions(state) {
            return state.trailersDimensions;
        },
        getShareNewRouteId(state) {
            return state.share_new_route_id;
        },
        getDropState(state) {
            return state.dropOpen;
        },
    },

});
