import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './front-store';
import http_client from './services/http_client';
import './assets/tailwind.css';
import 'tw-elements';
import VueHtmlToPaper from 'vue-html-to-paper';
import io from 'socket.io-client';

const socket = io(process.env.VUE_APP_ROOT);
socket.io.reconnectionDelay(5000)
Vue.config.productionTip = false;
Vue.prototype.$io = socket;
Vue.prototype.$http = http_client;
Vue.use(require('vue-moment'));
Vue.use(VueHtmlToPaper,{
      specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
      ],
      styles: [
          `${process.env.VUE_APP_ROOT}/static/styles/print.css`,
          './assets/tailwind.css',
          'vue-wysiwyg/dist/vueWysiwyg.css',
          '~vue-wysiwyg/dist/vueWysiwyg.css',
          'https://unpkg.com/leaflet@1.8.0/dist/leaflet.css',
          'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css'
      ],
    name: '_self'
    });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
