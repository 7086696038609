<template>
    <div class="absolute z-1 pin-t pin-l w-full h-full flex items-center justify-center loader"
         v-if="isLoading"
         style="background-color: rgba(248, 250, 252, 0.85);">
      <span class="font-medium text-grey-darker text-lg pb-4 text-center">
      <div class="spinner"></div>
    </span>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "Loader",
    computed: {
        ...mapGetters(['isLoading'])
    }
}
</script>

<style lang="scss" scoped>
.loader {
  top: 0;
  z-index: 1000;
  height: calc(100vh + 50%);
  .spinner {
    width: 40px;
    height: 40px;
    border: 2px solid #bfbdbd;
    border-top: 3px solid #498de2;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    animation: spin 1s linear infinite;
  }
}
</style>
